import React from 'react';
import {Container,Row,Col,Breadcrumb} from 'react-bootstrap';
import GetTc from '../components/GetTc';
const Tc=()=>{
    return(
        <div className="inner-body">
      <Container fluid>
        <Row className="inner">
          <div className="inner-head-outer">
            <div className="inner-head">
              <Col>
                <h2>TC / SLC</h2>
                <Breadcrumb>
                  <Breadcrumb.Item href="../">
                    <i className="fa fa-home"></i> Home
                  </Breadcrumb.Item>
                  <Breadcrumb.Item active>TC / SLC</Breadcrumb.Item>
                </Breadcrumb>
              </Col>
            </div>
          </div>
        </Row>
      </Container>
      <div className="mib relative">
        <div className="leftline"></div>

        <div className="bottomline"></div>
        <Container>
          <Row>
            <Col>
                <GetTc />
            </Col>
          </Row>
        </Container>
      </div>
      
      
    </div>
    );
}

export default Tc;