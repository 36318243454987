import React from "react";
import { Container, Row, Col, Breadcrumb, Table,Button } from "react-bootstrap";
import "./SchoolInformation.css";
const SchoolInformation = () => {
  return (
    <div className="inner-body">
      <Container fluid>
        <Row className="inner">
          <div className="inner-head-outer">
            <div className="inner-head">
              <Col>
                <h2>Mandatory Public Disclosure</h2>
                <Breadcrumb>
                  <Breadcrumb.Item href="../">
                    <i className="fa fa-home"></i> Home
                  </Breadcrumb.Item>
                  <Breadcrumb.Item active>
                    Mandatory Public Disclosure
                  </Breadcrumb.Item>
                </Breadcrumb>
              </Col>
            </div>
          </div>
        </Row>
      </Container>
      <div className="mib relative">
        <div className="leftline"></div>

        <div className="bottomline"></div>
        <Container fluid>
          <Row>
            <Col>
            <div align="center">
                  <Button href="http://www.mpsckp.com/school/pdf/mandatory_public_disclosure.pdf"> <i className="fa fa-file"></i> Mandatory Public Disclosure PDF</Button>
                </div>
            
              <div className="overflow-auto special">
                <h5>A: GENERAL INFORMATION:</h5>
                <Table>
                  <tr>
                    <th>SL. NO.</th>
                    <th>INFORMATION</th>
                    <th>DETAILS</th>
                  </tr>
                  <tr>
                    <td>1</td>
                    <td>NAME OF THE SCHOOL</td>
                    <td>Madhusudan Public School</td>
                  </tr>
                  <tr>
                    <td>2</td>
                    <td>AFFILIATION NO.(IF APPLICABLE)</td>
                    <td>3430268</td>
                  </tr>
                  <tr>
                    <td>3</td>
                    <td>SCHOOL CODE (IF APPLICABLE)</td>
                    <td>66462</td>
                  </tr>
                  <tr>
                    <td>4</td>
                    <td>COMPLETE ADDRESS WITH PIN CODE</td>
                    <td>
                      Vill : Asantalia, P.O.: Chakradharpur, West Singhbhum,
                      Jharkhand – 833102
                    </td>
                  </tr>
                  <tr>
                    <td>5</td>
                    <td>PRINCIPAL NAME &amp; QUALIFICATION:</td>
                    <td>Mr. K. Nagaraju &amp; M.A., B.Ed.</td>
                  </tr>
                  <tr>
                    <td>6</td>
                    <td>SCHOOL EMAIL ID</td>
                    <td>
                      <a href="mailto:mpsckp@gmail.com">mpsckp@gmail.com</a>
                    </td>
                  </tr>
                  <tr>
                    <td>7</td>
                    <td>CONTACT DETAILS (LANDLINE/MOBILE)</td>
                    <td>09955475737, 09934534059</td>
                  </tr>
                </Table>
                <h5>B: DOCUMENTS AND INFORMATION:</h5>
                <Table>
                  <tr>
                    <th>SL. NO.</th>
                    <th>DOCUMENTS/INFORMATION</th>
                    <th>UPLOAD DOCUMENTS</th>
                  </tr>
                  <tr>
                    <td>1</td>
                    <td>
                      COPIES OF AFFILIATION / UPGRADATION LETTER AND RECENT
                      EXTENSION OF AFFILIATION, IF ANY
                    </td>
                    <td>
                      <a
                        href="http://www.mpsckp.com/school/pdf/affiliation_letter.pdf"
                        target="_blank"
                        rel="noreferrer"
                      >
                        View
                      </a>
                    </td>
                  </tr>
                  <tr>
                    <td>2</td>
                    <td>
                      COPIES OF SOCIETIES / TRUST / COMPANY REGISTRATION /
                      RENEWAL CERTIFICATE, AS APPLICABLE
                    </td>
                    <td>
                      <a
                        href="http://www.mpsckp.com/school/pdf/society_registration.pdf"
                        target="_blank"
                        rel="noreferrer"
                      >
                        View
                      </a>
                    </td>
                  </tr>
                  <tr>
                    <td>3</td>
                    <td>
                      COPY OF NO OBJECTION CERTIFICATE (NOC) ISSUED, IF
                      APPLICABLE, BY THE STATE GOVT./UT
                    </td>
                    <td>
                      <a
                        href="http://www.mpsckp.com/school/pdf/noc.pdf"
                        target="_blank"
                        rel="noreferrer"
                      >
                        View
                      </a>
                    </td>
                  </tr>
                  <tr>
                    <td>4</td>
                    <td>
                      COPIES OF RECOGNITION CERTIFICATE UNDER RTE ACT, 2009, AND
                      IT’S RENEWAL IF APPLICABLE
                    </td>
                    <td>
                      <a
                        href="http://www.mpsckp.com/school/pdf/rte.pdf"
                        target="_blank"
                        rel="noreferrer"
                      >
                        View
                      </a>
                    </td>
                  </tr>
                  <tr>
                    <td>5</td>
                    <td>
                      COPY OF VALID BUILDING SAFETY CERTIFICATE AS PER THE
                      NATIONAL BUILDING CODE
                    </td>
                    <td>
                      <a
                        href="http://www.mpsckp.com/school/pdf/building_safety.pdf"
                        target="_blank"
                        rel="noreferrer"
                      >
                        View
                      </a>
                    </td>
                  </tr>
                  <tr>
                    <td>6</td>
                    <td>
                      COPY OF VALID FIRE SAFETY CERTIFICATE ISSUED BY THE
                      COMPETENT AUTHORITY
                    </td>
                    <td>
                      <a
                        href="http://www.mpsckp.com/school/pdf/fire_safety.pdf"
                        target="_blank"
                        rel="noreferrer"
                      >
                        View
                      </a>
                    </td>
                  </tr>
                  <tr>
                    <td>7</td>
                    <td>
                      COPY OF THE DEO CERTIFICATE SUBMITTED BY THE SCHOOL FOR
                      AFFILIATION / UPGRADATION / EXTENSION OF AFFILIATION OR
                      SELF CERTIFICATION BY SCHOOL
                    </td>
                    <td>
                      <a
                        href="http://www.mpsckp.com/school/pdf/deo_certificate.pdf"
                        target="_blank"
                        rel="noreferrer"
                      >
                        View
                      </a>
                    </td>
                  </tr>
                  <tr>
                    <td>8</td>
                    <td>
                      COPIES OF VALID WATER, HEALTH AND SANITATION CERTIFICATES
                    </td>
                    <td>
                      <a
                        href="http://www.mpsckp.com/school/pdf/drinking_water.pdf"
                        target="_blank"
                        rel="noreferrer"
                      >
                        View
                      </a>
                    </td>
                  </tr>
                 
                </Table>
                <h5>C: RESULT AND ACADEMICS:</h5>
                <Table>
                  <tr>
                    <th>SL. NO.</th>
                    <th>DOCUMENTS/INFORMATION</th>
                    <th>UPLOAD DOCUMENTS</th>
                  </tr>
                  <tr>
                    <td>1</td>
                    <td>FEE STRUCTURE OF THE SCHOOL</td>
                    <td>
                      <a
                        href="http://www.mpsckp.com/school/pdf/fee_structure.pdf"
                        target="_blank"
                        rel="noreferrer"
                      >
                        View
                      </a>
                    </td>
                  </tr>
                  <tr>
                    <td>2</td>
                    <td>ANNUAL ACADEMIC CALANDER.</td>
                    <td>
                      <a
                        href="http://www.mpsckp.com/school/pdf/academic_calender.pdf"
                        target="_blank"
                        rel="noreferrer"
                      >
                        View
                      </a>
                    </td>
                  </tr>
                  <tr>
                    <td>3</td>
                    <td>LIST OF SCHOOL MANAGEMENT COMMITTEE (SMC)</td>
                    <td>
                      <a
                        href="http://www.mpsckp.com/school/pdf/smc.pdf"
                        target="_blank"
                        rel="noreferrer"
                      >
                        View
                      </a>
                    </td>
                  </tr>
                  <tr>
                    <td>4</td>
                    <td>LIST OF PARENTS TEACHERS ASSOCIATION (PTA) MEMBERS</td>
                    <td>
                      <a
                        href="http://www.mpsckp.com/school/pdf/pta_members.pdf"
                        target="_blank"
                        rel="noreferrer"
                      >
                        View
                      </a>
                    </td>
                  </tr>
                  <tr>
                    <td>5</td>
                    <td>
                      LAST THREE-YEAR RESULT OF THE BOARD EXAMINATION AS PER
                      APPLICABLILITY
                    </td>
                    <td>
                    <a href="http://www.mpsckp.com/school/pdf/result.pdf"
                            target="_blank"
                            rel="noreferrer"
                          >
                           View
                          </a>
                    </td>
                  </tr>
                </Table>
                <h5>D: STAFF (TEACHING)</h5>
                <Table>
                  <tr>
                    <th>SL. NO.</th>
                    <th>INFORMATION</th>
                    <th>DETAILS</th>
                  </tr>
                  <tr>
                    <td>1</td>
                    <td>PRINCIPAL</td>
                    <td>Mr. K. Nagaraju</td>
                  </tr>
                  <tr>
                    <td>2</td>
                    <td>VICE PRINCIPAL</td>
                    <td>Mrs. Arti Korwar</td>
                  </tr>
                  <tr>
                    <td>3</td>
                    <td>
                      TOTAL NO. OF TEACHERS
                      <ul>
                        <li>PGT</li>
                        <li>TGT</li>
                        <li>PET</li>
                        <li>PRT</li>
                        <li>NTT</li>
                      </ul>
                    </td>
                    <td>
                      Total : 68
                      <ul>
                        <li>15</li>
                        <li>12</li>
                        <li>02</li>
                        <li>28</li>
                        <li>11</li>
                      </ul>
                    </td>
                  </tr>
                  <tr>
                    <td>4</td>
                    <td>TEACHERS SECTION RATIO</td>
                    <td>1.5:1</td>
                  </tr>
                  <tr>
                    <td>5</td>
                    <td>DETAILS OF SPECIAL EDUCATOR</td>
                    <td>1</td>
                  </tr>
                  <tr>
                    <td>6</td>
                    <td>DETAILS OF COUNSELLOR AND WELNESS TEACHER</td>
                    <td>1</td>
                  </tr>
                </Table>
                <h5>E: SCHOOL INFRASTRUCTURE:</h5>
                <Table>
                  <tr>
                    <th>SL. NO.</th>
                    <th>INFORMATION</th>
                    <th>DETAILS</th>
                  </tr>
                  <tr>
                    <td>1</td>
                    <td>TOTAL CAMPUS AREA OF THE SCHOOL (IN SQUARE MTR)</td>
                    <td>8093.7128 SQUARE MTR</td>
                  </tr>
                  <tr>
                    <td>2</td>
                    <td>NO. AND SIZE OF THE CLASS ROOMS (IN SQUARE MTR)</td>
                    <td>52 Rooms &amp; Size 46.45 SQUARE MTR</td>
                  </tr>
                  <tr>
                    <td rowSpan={2}>3</td>
                    <td>
                      NO. AND SIZE OF LABORATORIES INCLUDING COMPUTER LABS (IN
                      SQ MTR)
                    </td>
                    <td>3 LABS &amp; SIZE 70.51 SQ. MTR</td>
                  </tr>
                  <tr>
                    <td>NO. AND SIZE OF COMPUTER LABS(IN SQ MTR)</td>
                    <td>1 LABS &amp; SIZE 85.47 SQ. MTR</td>
                  </tr>
                  <tr>
                    <td>4</td>
                    <td>INTERNET FACILITY (Y/N)</td>
                    <td>Y</td>
                  </tr>
                  <tr>
                    <td>5</td>
                    <td>NO. OF GIRLS TOILETS</td>
                    <td>60</td>
                  </tr>
                  <tr>
                    <td>6</td>
                    <td>NO. OF BOYS TOILETS</td>
                    <td>80</td>
                  </tr>
                  <tr>
                    <td>7</td>
                    <td>
                      LINK OF YOUTUBE VIDEO OF THE INSPECTION OF SCHOOL COVERING
                      THE INFRASTRUCTURE OF THE SCHOOL
                    </td>
                    <td>
                      <a
                        href="https://www.youtube.com/watch?v=fPvnfSlgN6o"
                        target="_blank"
                        rel="noreferrer"
                      >
                        Link 1
                      </a>{" "}
                      |{" "}
                      <a
                        href="https://www.youtube.com/watch?v=75I5JwbWeSk"
                        target="_blank"
                        rel="noreferrer"
                      >
                        Link 2
                      </a>{" "}
                      |{" "}
                      <a
                        href="https://www.youtube.com/watch?v=mztJbcr-ZPw"
                        target="_blank"
                        rel="noreferrer"
                      >
                        Link 3
                      </a>
                    </td>
                  </tr>
                </Table>
              </div>
            </Col>
          </Row>
        </Container>
      </div>
    </div>
  );
};

export default SchoolInformation;
