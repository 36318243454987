import React from "react";
import { Container, Row, Col, Breadcrumb } from "react-bootstrap";
import "./StudentArena.css";
const StudentArena = () => {
  const students = [
    {
      house: "alfa",
      members: [
        {
          name: "Basant Mahto",
          post: "Captain",
          photo: "https://www.mpsckp.com/images/house/bm.jpg",
        },
        {
          name: "Simran Mahato",
          post: "Captain",
          photo: "https://www.mpsckp.com/images/house/sm.jpg",
        },
        {
          name: "Rahul Bobonga",
          post: "Vice Captain",
          photo: "https://www.mpsckp.com/images/house/rb.jpg",
        },
        {
          name: "Riya Joytishi",
          post: "Vice Captain",
          photo: "https://www.mpsckp.com/images/house/rj.jpg",
        },
      ],
    },
    {
      house: "bravo",
      members: [
        {
          name: "Vicky Manjhi",
          post: "Captain",
          photo: "https://www.mpsckp.com/images/house/vm.jpg",
        },
        {
          name: "Vidhi Raj Hindwar",
          post: "Captain",
          photo: "https://www.mpsckp.com/images/house/vrh.jpg",
        },
        {
          name: "Manjit Kumar",
          post: "Vice Captain",
          photo: "https://www.mpsckp.com/images/house/mk.jpg",
        },
        {
          name: "Ritu Kumari Mahto",
          post: "Vice Captain",
          photo: "https://www.mpsckp.com/images/house/rkm.jpg",
        },
      ],
    },
    {
      house: "chetak",
      members: [
        {
          name: "Rudra Jyoti Shit",
          post: "Captain",
          photo: "https://www.mpsckp.com/images/house/rjs.jpg",
        },
        {
          name: "Ishika Jana",
          post: "Captain",
          photo: "https://www.mpsckp.com/images/house/ij.jpg",
        },
        {
          name: "Anshuman Mahato",
          post: "Vice Captain",
          photo: "https://www.mpsckp.com/images/house/am.jpg",
        },
        {
          name: "Khushboo Soy",
          post: "Vice Captain",
          photo: "https://www.mpsckp.com/images/house/ks.jpg",
        },
      ],
    },
    {
      house: "delta",
      members: [
        {
          name: "Somnath Tripathy",
          post: "Captain",
          photo: "https://www.mpsckp.com/images/house/st.jpg",
        },
        {
          name: "Arsu Hansdah",
          post: "Captain",
          photo: "https://www.mpsckp.com/images/house/ah.jpg",
        },
        {
          name: "Raj Soy",
          post: "Vice Captain",
          photo: "https://www.mpsckp.com/images/house/rs.jpg",
        },
        {
          name: "Janhavi Kumari",
          post: "Vice Captain",
          photo: "https://www.mpsckp.com/images/house/jk.jpg",
        },
      ],
    },
  ];
  return (
    <div className="inner-body">
      <Container fluid>
        <Row className="inner">
          <div className="inner-head-outer">
            <div className="inner-head">
              <Col>
                <h2>Student Arena</h2>
                <Breadcrumb>
                  <Breadcrumb.Item href="../">
                    <i className="fa fa-home"></i> Home
                  </Breadcrumb.Item>
                  <Breadcrumb.Item active>Student Arena</Breadcrumb.Item>
                </Breadcrumb>
              </Col>
            </div>
          </div>
        </Row>
      </Container>
      <div className="mib relative">
        <div className="leftline"></div>

        <div className="bottomline"></div>
        <Container>
          <Row>
            <Col>
              <p className="text-center">
                To ensure healthy and constructive competition among the
                students and to develop team spirit the students are divided
                under four group:
              </p>
              <div className="circlearea">
                <div className="circle">Alfa</div>
                <div className="circle">Bravo</div>
                <div className="circle">Chetak</div>
                <div className="circle">Delta</div>
              </div>
            </Col>
          </Row>

          <Row>
            <Col>
              <div className="house">
                {students.map((data) => {
                  return (
                    <div>
                      <Row>
                        <Col>
                          <h3>{data.house}</h3>
                        </Col>
                      </Row>
                      <Row>
                        {data.members.map((d) => {
                          return (
                            <Col md={3}>
                              <div className="sArea">
                                <img src={d.photo} alt=" " />
                                <h6>{d.name}</h6>
                                <p>{d.post}</p>
                              </div>
                            </Col>
                          );
                        })}
                      </Row>
                    </div>
                  );
                })}
              </div>
            </Col>
          </Row><Row>
            <Col>
                <div className="saline"></div>
            </Col>
          </Row>

          <Row>
            <Col md={3}>
              <div className="saarea">
                <img
                  src="https://www.mpsckp.com/images/2024hb.jpg"
                  alt="Head Boy"
                />
                <div className="saareadetails">
                  <p>SAMBHAV NANDA</p>
                  <p>Head Boy</p>
                </div>
              </div>
            </Col>
            <Col md={3}>
              <div className="saarea">
                <img
                  src="https://www.mpsckp.com/images/2024hg.jpg"
                  alt="Head Girl"
                />
                <div className="saareadetails">
                  <p>SWETA BODRA</p>
                  <p>Head Girl</p>
                </div>
              </div>
            </Col>
            <Col md={3}>
              <div className="saarea">
                <img
                  src="https://www.mpsckp.com/images/2024vhb.jpg"
                  alt="Vice Head Boy"
                />
                <div className="saareadetails">
                  <p>SHIV SHANKAR BOBONGA</p>
                  <p>Vice Head Boy</p>
                </div>
              </div>
            </Col>
            <Col md={3}>
              <div className="saarea">
                <img
                  src="https://www.mpsckp.com/images/2024vhg.jpg"
                  alt="Vice Head Girl"
                />
                <div className="saareadetails">
                  <p>LUCKY SAHU</p>
                  <p>Vice Head Girl</p>
                </div>
              </div>
            </Col>
          </Row>
        </Container>
      </div>
    </div>
  );
};

export default StudentArena;
