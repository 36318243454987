import React from "react";
import { Container, Row, Col, Breadcrumb } from "react-bootstrap";
import "photoswipe/dist/photoswipe.css";
import "photoswipe/dist/default-skin/default-skin.css";
// import { Gallery, Item } from "react-photoswipe-gallery";
import { Link } from "react-router-dom";
import "../Gallery.css";
import ImageGallery from "react-image-gallery";
import "react-image-gallery/styles/css/image-gallery.css";
const AnnualSports = () => {
  const numbers = [
    1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18,
  ];
  // const i;
  const images = [
    {
      original: "http://www.mpsckp.com/gallery/annual_day_23/1.jpg",
      thumbnail: "http://www.mpsckp.com/gallery/annual_day_23/1.jpg",
    },
    {
      original: "http://www.mpsckp.com/gallery/annual_day_23/2.jpg",
      thumbnail: "http://www.mpsckp.com/gallery/annual_day_23/2.jpg",
    },
    {
      original: "http://www.mpsckp.com/gallery/annual_day_23/3.jpg",
      thumbnail: "http://www.mpsckp.com/gallery/annual_day_23/3.jpg",
    },
    {
      original: "http://www.mpsckp.com/gallery/annual_day_23/4.jpg",
      thumbnail: "http://www.mpsckp.com/gallery/annual_day_23/4.jpg",
    },
    {
      original: "http://www.mpsckp.com/gallery/annual_day_23/5.jpg",
      thumbnail: "http://www.mpsckp.com/gallery/annual_day_23/5.jpg",
    },
    {
      original: "http://www.mpsckp.com/gallery/annual_day_23/6.jpg",
      thumbnail: "http://www.mpsckp.com/gallery/annual_day_23/6.jpg",
    },
    {
      original: "http://www.mpsckp.com/gallery/annual_day_23/7.jpg",
      thumbnail: "http://www.mpsckp.com/gallery/annual_day_23/7.jpg",
    },
    {
      original: "http://www.mpsckp.com/gallery/annual_day_23/8.jpg",
      thumbnail: "http://www.mpsckp.com/gallery/annual_day_23/8.jpg",
    },
    {
      original: "http://www.mpsckp.com/gallery/annual_day_23/9.jpg",
      thumbnail: "http://www.mpsckp.com/gallery/annual_day_23/9.jpg",
    },
    {
      original: "http://www.mpsckp.com/gallery/annual_day_23/10.jpg",
      thumbnail: "http://www.mpsckp.com/gallery/annual_day_23/10.jpg",
    },
    {
      original: "http://www.mpsckp.com/gallery/annual_day_23/11.jpg",
      thumbnail: "http://www.mpsckp.com/gallery/annual_day_23/11.jpg",
    },
    {
      original: "http://www.mpsckp.com/gallery/annual_day_23/12.jpg",
      thumbnail: "http://www.mpsckp.com/gallery/annual_day_23/12.jpg",
    },
    {
      original: "http://www.mpsckp.com/gallery/annual_day_23/13.jpg",
      thumbnail: "http://www.mpsckp.com/gallery/annual_day_23/13.jpg",
    },
    {
      original: "http://www.mpsckp.com/gallery/annual_day_23/14.jpg",
      thumbnail: "http://www.mpsckp.com/gallery/annual_day_23/14.jpg",
    },
    {
      original: "http://www.mpsckp.com/gallery/annual_day_23/15.jpg",
      thumbnail: "http://www.mpsckp.com/gallery/annual_day_23/15.jpg",
    },
    {
      original: "http://www.mpsckp.com/gallery/annual_day_23/16.jpg",
      thumbnail: "http://www.mpsckp.com/gallery/annual_day_23/16.jpg",
    },
    {
      original: "http://www.mpsckp.com/gallery/annual_day_23/17.jpg",
      thumbnail: "http://www.mpsckp.com/gallery/annual_day_23/17.jpg",
    },
    {
      original: "http://www.mpsckp.com/gallery/annual_day_23/18.jpg",
      thumbnail: "http://www.mpsckp.com/gallery/annual_day_23/18.jpg",
    },
    {
      original: "http://www.mpsckp.com/gallery/annual_day_23/19.jpg",
      thumbnail: "http://www.mpsckp.com/gallery/annual_day_23/19.jpg",
    },
    {
      original: "http://www.mpsckp.com/gallery/annual_day_23/20.jpg",
      thumbnail: "http://www.mpsckp.com/gallery/annual_day_23/20.jpg",
    },
    {
      original: "http://www.mpsckp.com/gallery/annual_day_23/21.jpg",
      thumbnail: "http://www.mpsckp.com/gallery/annual_day_23/21.jpg",
    },
    {
      original: "http://www.mpsckp.com/gallery/annual_day_23/22.jpg",
      thumbnail: "http://www.mpsckp.com/gallery/annual_day_23/22.jpg",
    },
    {
      original: "http://www.mpsckp.com/gallery/annual_day_23/23.jpg",
      thumbnail: "http://www.mpsckp.com/gallery/annual_day_23/23.jpg",
    },
    {
      original: "http://www.mpsckp.com/gallery/annual_day_23/24.jpg",
      thumbnail: "http://www.mpsckp.com/gallery/annual_day_23/24.jpg",
    },
    {
      original: "http://www.mpsckp.com/gallery/annual_day_23/25.jpg",
      thumbnail: "http://www.mpsckp.com/gallery/annual_day_23/25.jpg",
    },
    {
      original: "http://www.mpsckp.com/gallery/annual_day_23/26.jpg",
      thumbnail: "http://www.mpsckp.com/gallery/annual_day_23/26.jpg",
    },
    {
      original: "http://www.mpsckp.com/gallery/annual_day_23/27.jpg",
      thumbnail: "http://www.mpsckp.com/gallery/annual_day_23/27.jpg",
    },
    {
      original: "http://www.mpsckp.com/gallery/annual_day_23/28.jpg",
      thumbnail: "http://www.mpsckp.com/gallery/annual_day_23/28.jpg",
    },
    {
      original: "http://www.mpsckp.com/gallery/annual_day_23/29.jpg",
      thumbnail: "http://www.mpsckp.com/gallery/annual_day_23/29.jpg",
    },
    {
      original: "http://www.mpsckp.com/gallery/annual_day_23/30.jpg",
      thumbnail: "http://www.mpsckp.com/gallery/annual_day_23/30.jpg",
    },
    {
      original: "http://www.mpsckp.com/gallery/annual_day_23/31.jpg",
      thumbnail: "http://www.mpsckp.com/gallery/annual_day_23/31.jpg",
    },
    {
      original: "http://www.mpsckp.com/gallery/annual_day_23/32.jpg",
      thumbnail: "http://www.mpsckp.com/gallery/annual_day_23/32.jpg",
    },
    {
      original: "http://www.mpsckp.com/gallery/annual_day_23/33.jpg",
      thumbnail: "http://www.mpsckp.com/gallery/annual_day_23/33.jpg",
    },
    {
      original: "http://www.mpsckp.com/gallery/annual_day_23/34.jpg",
      thumbnail: "http://www.mpsckp.com/gallery/annual_day_23/34.jpg",
    },
    {
      original: "http://www.mpsckp.com/gallery/annual_day_23/35.jpg",
      thumbnail: "http://www.mpsckp.com/gallery/annual_day_23/35.jpg",
    },
    {
      original: "http://www.mpsckp.com/gallery/annual_day_23/36.jpg",
      thumbnail: "http://www.mpsckp.com/gallery/annual_day_23/36.jpg",
    },
    {
      original: "http://www.mpsckp.com/gallery/annual_day_23/37.jpg",
      thumbnail: "http://www.mpsckp.com/gallery/annual_day_23/37.jpg",
    },
    {
      original: "http://www.mpsckp.com/gallery/annual_day_23/38.jpg",
      thumbnail: "http://www.mpsckp.com/gallery/annual_day_23/38.jpg",
    },
    {
      original: "http://www.mpsckp.com/gallery/annual_day_23/39.jpg",
      thumbnail: "http://www.mpsckp.com/gallery/annual_day_23/39.jpg",
    },
    {
      original: "http://www.mpsckp.com/gallery/annual_day_23/40.jpg",
      thumbnail: "http://www.mpsckp.com/gallery/annual_day_23/40.jpg",
    },
    {
      original: "http://www.mpsckp.com/gallery/annual_day_23/41.jpg",
      thumbnail: "http://www.mpsckp.com/gallery/annual_day_23/41.jpg",
    },
    {
      original: "http://www.mpsckp.com/gallery/annual_day_23/42.jpg",
      thumbnail: "http://www.mpsckp.com/gallery/annual_day_23/42.jpg",
    },
    {
      original: "http://www.mpsckp.com/gallery/annual_day_23/43.jpg",
      thumbnail: "http://www.mpsckp.com/gallery/annual_day_23/43.jpg",
    },
    {
      original: "http://www.mpsckp.com/gallery/annual_day_23/44.jpg",
      thumbnail: "http://www.mpsckp.com/gallery/annual_day_23/44.jpg",
    },
    {
      original: "http://www.mpsckp.com/gallery/annual_day_23/45.jpg",
      thumbnail: "http://www.mpsckp.com/gallery/annual_day_23/45.jpg",
    },
    {
      original: "http://www.mpsckp.com/gallery/annual_day_23/46.jpg",
      thumbnail: "http://www.mpsckp.com/gallery/annual_day_23/46.jpg",
    },
    {
      original: "http://www.mpsckp.com/gallery/annual_day_23/47.jpg",
      thumbnail: "http://www.mpsckp.com/gallery/annual_day_23/47.jpg",
    },
    {
      original: "http://www.mpsckp.com/gallery/annual_day_23/48.jpg",
      thumbnail: "http://www.mpsckp.com/gallery/annual_day_23/48.jpg",
    },
    {
      original: "http://www.mpsckp.com/gallery/annual_day_23/49.jpg",
      thumbnail: "http://www.mpsckp.com/gallery/annual_day_23/49.jpg",
    },
    {
      original: "http://www.mpsckp.com/gallery/annual_day_23/50.jpg",
      thumbnail: "http://www.mpsckp.com/gallery/annual_day_23/50.jpg",
    },
  ];

  // const listItems = numbers.map((number, index) => {

  //     return (

  //       [{

  //       }

  //     ]
  //     );

  // });
  // console.log(listItems);

  return (
    <div className="inner-body">
      <Container fluid>
        <Row className="inner">
          <div className="inner-head-outer">
            <div className="inner-head">
              <Col>
                <h2>Annual Day 2023</h2>
                <Breadcrumb>
                  <Breadcrumb.Item href="../">
                    <i className="fa fa-home"></i> Home
                  </Breadcrumb.Item>
                  <Breadcrumb.Item>
                    <Link to={"/mgallery"}>
                      <i className="fa fa-camera"></i> Gallery
                    </Link>
                  </Breadcrumb.Item>
                  <Breadcrumb.Item active>Annual Day 2023</Breadcrumb.Item>
                </Breadcrumb>
              </Col>
            </div>
          </div>
        </Row>
      </Container>
      <div className="mib relative">
        <div className="leftline"></div>

        <div className="bottomline"></div>
        <Container>
          {/* <Gallery>{listItems}</Gallery> */}
          <Row>
            <Col>
              <ImageGallery items={images} lazyLoad={true}/>
            </Col>
          </Row>
        </Container>
      </div>
    </div>
  );
};

export default AnnualSports;
