import React from "react";
import { Container, Row, Col, Breadcrumb } from "react-bootstrap";
import "./Result.css";
const Result = () => {
  return (
    <div className="inner-body">
      <Container fluid>
        <Row className="inner">
          <div className="inner-head-outer">
            <div className="inner-head">
              <Col>
                <h2>Result</h2>
                <Breadcrumb>
                  <Breadcrumb.Item href="../">
                    <i className="fa fa-home"></i> Home
                  </Breadcrumb.Item>
                  <Breadcrumb.Item active>Result</Breadcrumb.Item>
                </Breadcrumb>
              </Col>
            </div>
          </div>
        </Row>
      </Container>
      <div className="mib relative">
        <div className="leftline"></div>

        <div className="bottomline"></div>
        <Container>
          <Row className="result">
            <Col>
            <h4>CLASS XII(SCIENCE) TOPPERS - 2024</h4></Col>
          </Row>
        </Container>

        <Container>
          <Row>
            <Col md={3}>
              <div className="resultbox">
                <img src="https://www.mpsckp.com/images/toppers/2024/12_science_1.jpg" alt="" />
                <h5>Deepika Rani Nag</h5>
                <h6>93.20</h6>
              </div>
            </Col>
            <Col md={3}>
              <div className="resultbox">
                <img src="https://www.mpsckp.com/images/toppers/2024/12_science_2.jpg" alt="" />
                <h5>Pranita Mishra</h5>
                <h6>91.80</h6>
              </div>
            </Col>
            <Col md={3}>
              <div className="resultbox">
                <img src="https://www.mpsckp.com/images/toppers/2024/12_science_3.jpg" alt="" />
                <h5>Anugrah Bhengra</h5>
                <h6>91.80</h6>
              </div>
            </Col>
            <Col md={3}>
              <div className="resultbox">
                <img src="https://www.mpsckp.com/images/toppers/2024/12_science_4.jpg" alt="" />
                <h5>Ayush Kumar Singh</h5>
                <h6>91.40</h6>
              </div>
            </Col>
            <Col md={3}>
              <div className="resultbox">
                <img src="https://www.mpsckp.com/images/toppers/2024/12_science_5.jpg" alt="" />
                <h5>Ravi Shankar Mahto</h5>
                <h6>90.00</h6>
              </div>
            </Col>
            <Col md={3}>
              <div className="resultbox">
                <img src="https://www.mpsckp.com/images/toppers/2024/12_science_6.jpg" alt="" />
                <h5>Sourangshu Ghosh</h5>
                <h6>88.60</h6>
              </div>
            </Col>
            <Col md={3}>
              <div className="resultbox">
                <img src="https://www.mpsckp.com/images/toppers/2024/12_science_7.jpg" alt="" />
                <h5>Jay Kumar Mahto</h5>
                <h6>87.40</h6>
              </div>
            </Col>
            <Col md={3}>
              <div className="resultbox">
                <img src="https://www.mpsckp.com/images/toppers/2024/12_science_8.jpg" alt="" />
                <h5>Prince Kerai</h5>
                <h6>86.80</h6>
              </div>
            </Col>
            <Col md={3}>
              <div className="resultbox">
                <img src="https://www.mpsckp.com/images/toppers/2024/12_science_9.jpg" alt="" />
                <h5>Manisha Kumari</h5>
                <h6>84.60</h6>
              </div>
            </Col>
            <Col md={3}>
              <div className="resultbox">
                <img src="https://www.mpsckp.com/images/toppers/2024/12_science_10.jpg" alt="" />
                <h5>Kriti Sawaiyan</h5>
                <h6>83.60</h6>
              </div>
            </Col>
            <Col md={3}>
              <div className="resultbox">
                <img src="https://www.mpsckp.com/images/toppers/2024/12_science_11.jpg" alt="" />
                <h5>Aprajita Samad</h5>
                <h6>82.40</h6>
              </div>
            </Col>
          </Row>
        </Container>
        <Container>
          <Row className="result">
            <Col>
            <h4>CLASS XII(ARTS) TOPPERS - 2024</h4></Col>
          </Row>
        </Container>

        <Container>
          <Row>
            <Col md={3}>
              <div className="resultbox">
                <img src="https://www.mpsckp.com/images/toppers/2024/12_arts_1.jpg" alt="" />
                <h5>Sanchita Mahato</h5>
                <h6>91.80</h6>
              </div>
            </Col>
            <Col md={3}>
              <div className="resultbox">
                <img src="https://www.mpsckp.com/images/toppers/2024/12_arts_2.jpg" alt="" />
                <h5>Jaideep Roy</h5>
                <h6>89.40</h6>
              </div>
            </Col>
            <Col md={3}>
              <div className="resultbox">
                <img src="https://www.mpsckp.com/images/toppers/2024/12_arts_3.jpg" alt="" />
                <h5>Jayta Sarkar</h5>
                <h6>88.20</h6>
              </div>
            </Col>
            <Col md={3}>
              <div className="resultbox">
                <img src="https://www.mpsckp.com/images/toppers/2024/12_arts_4.jpg" alt="" />
                <h5>Priya Boypai</h5>
                <h6>87.40</h6>
              </div>
            </Col>
            <Col md={3}>
              <div className="resultbox">
                <img src="https://www.mpsckp.com/images/toppers/2024/12_arts_5.jpg" alt="" />
                <h5>Nirmal Palit Bakshi</h5>
                <h6>86.00</h6>
              </div>
            </Col>
            <Col md={3}>
              <div className="resultbox">
                <img src="https://www.mpsckp.com/images/toppers/2024/12_arts_6.jpg" alt="" />
                <h5>U Amisha</h5>
                <h6>83.00</h6>
              </div>
            </Col>
            <Col md={3}>
              <div className="resultbox">
                <img src="https://www.mpsckp.com/images/toppers/2024/12_arts_7.jpg" alt="" />
                <h5>Piyali Singh</h5>
                <h6>82.40</h6>
              </div>
            </Col>
            <Col md={3}>
              <div className="resultbox">
                <img src="https://www.mpsckp.com/images/toppers/2024/12_arts_8.jpg" alt="" />
                <h5>Sagar Mundri</h5>
                <h6>81.20</h6>
              </div>
            </Col>
            <Col md={3}>
              <div className="resultbox">
                <img src="https://www.mpsckp.com/images/toppers/2024/12_arts_9.jpg" alt="" />
                <h5>Aditi Mahto</h5>
                <h6>80.40</h6>
              </div>
            </Col>
            <Col md={3}>
              <div className="resultbox">
                <img src="https://www.mpsckp.com/images/toppers/2024/12_arts_10.jpg" alt="" />
                <h5>Richa Tripti Baba</h5>
                <h6>79.20</h6>
              </div>
            </Col>
          </Row>
        </Container>
        <Container>
          <Row className="result">
            <Col>
            <h4>CLASS X TOPPERS - 2024</h4></Col>
          </Row>
        </Container>

        <Container>
          <Row>
            <Col md={3}>
              <div className="resultbox">
                <img src="https://www.mpsckp.com/images/toppers/2024/10_1.jpg" alt="" />
                <h5>Raj Mahto</h5>
                <h6>97.80</h6>
              </div>
            </Col>
            <Col md={3}>
              <div className="resultbox">
                <img src="https://www.mpsckp.com/images/toppers/2024/10_2.jpg" alt="" />
                <h5>Sujal Sahoo</h5>
                <h6>97.00</h6>
              </div>
            </Col>
            <Col md={3}>
              <div className="resultbox">
                <img src="https://www.mpsckp.com/images/toppers/2024/10_3.jpg" alt="" />
                <h5>Ayush Raj</h5>
                <h6>96.00</h6>
              </div>
            </Col>
            <Col md={3}>
              <div className="resultbox">
                <img src="https://www.mpsckp.com/images/toppers/2024/10_4.jpg" alt="" />
                <h5>Jagdish Mahto</h5>
                <h6>95.00</h6>
              </div>
            </Col>
            <Col md={3}>
              <div className="resultbox">
                <img src="https://www.mpsckp.com/images/toppers/2024/10_5.jpg" alt="" />
                <h5>Raj Shankar</h5>
                <h6>94.60</h6>
              </div>
            </Col>
            <Col md={3}>
              <div className="resultbox">
                <img src="https://www.mpsckp.com/images/toppers/2024/10_6.jpg" alt="" />
                <h5>Devanand Mahato</h5>
                <h6>94.60</h6>
              </div>
            </Col>
            <Col md={3}>
              <div className="resultbox">
                <img src="https://www.mpsckp.com/images/toppers/2024/10_7.jpg" alt="" />
                <h5>Anupama Mahato</h5>
                <h6>94.20</h6>
              </div>
            </Col>
            <Col md={3}>
              <div className="resultbox">
                <img src="https://www.mpsckp.com/images/toppers/2024/10_8.jpg" alt="" />
                <h5>Dharya J Sanghvi</h5>
                <h6>94.20</h6>
              </div>
            </Col>
            <Col md={3}>
              <div className="resultbox">
                <img src="https://www.mpsckp.com/images/toppers/2024/10_9.jpg" alt="" />
                <h5>Smriti Mahto</h5>
                <h6>93.80</h6>
              </div>
            </Col>
            <Col md={3}>
              <div className="resultbox">
                <img src="https://www.mpsckp.com/images/toppers/2024/10_10.jpg" alt="" />
                <h5>Ansh Raj Pradhan</h5>
                <h6>93.60</h6>
              </div>
            </Col>
            <Col md={3}>
              <div className="resultbox">
                <img src="https://www.mpsckp.com/images/toppers/2024/10_11.jpg" alt="" />
                <h5>Shakti Mahato</h5>
                <h6>92.80</h6>
              </div>
            </Col>
            <Col md={3}>
              <div className="resultbox">
                <img src="https://www.mpsckp.com/images/toppers/2024/10_12.jpg" alt="" />
                <h5>Bhawesh Pradhan</h5>
                <h6>92.00</h6>
              </div>
            </Col>
            <Col md={3}>
              <div className="resultbox">
                <img src="https://www.mpsckp.com/images/toppers/2024/10_13.jpg" alt="" />
                <h5>Ankita Mandal</h5>
                <h6>91.20</h6>
              </div>
            </Col>
            <Col md={3}>
              <div className="resultbox">
                <img src="https://www.mpsckp.com/images/toppers/2024/10_14.jpg" alt="" />
                <h5>Ritesh Mahato</h5>
                <h6>90.80</h6>
              </div>
            </Col>
            <Col md={3}>
              <div className="resultbox">
                <img src="https://www.mpsckp.com/images/toppers/2024/10_15.jpg" alt="" />
                <h5>Rahul Mahato</h5>
                <h6>90.80</h6>
              </div>
            </Col>
            <Col md={3}>
              <div className="resultbox">
                <img src="https://www.mpsckp.com/images/toppers/2024/10_16.jpg" alt="" />
                <h5>Jyotika Khalkho</h5>
                <h6>90.60</h6>
              </div>
            </Col>
            <Col md={3}>
              <div className="resultbox">
                <img src="https://www.mpsckp.com/images/toppers/2024/10_17.jpg" alt="" />
                <h5>Om Prakash Mahato</h5>
                <h6>90.60</h6>
              </div>
            </Col>
            <Col md={3}>
              <div className="resultbox">
                <img src="https://www.mpsckp.com/images/toppers/2024/10_18.jpg" alt="" />
                <h5>Preeti Mahato</h5>
                <h6>90.20</h6>
              </div>
            </Col>
            <Col md={3}>
              <div className="resultbox">
                <img src="https://www.mpsckp.com/images/toppers/2024/10_19.jpg" alt="" />
                <h5>Roshan Pradhan</h5>
                <h6>90.00</h6>
              </div>
            </Col>
          </Row>
          </Container>
        <Container>
          <Row className="result">
            <Col>
            <h4>CLASS XII TOPPERS - 2023</h4></Col>
          </Row>
        </Container>
        <Container>
          <Row>
            <Col md={3}>
              <div className="resultbox">
                <img src="https://www.mpsckp.com/images/toppers/2023/12_1.jpg" alt="" />
                <h5>Rohit Kumar Mahato</h5>
                <h6>92.40</h6>
              </div>
            </Col>
            <Col md={3}>
              <div className="resultbox">
              <img src="https://www.mpsckp.com/images/toppers/2023/12_2.jpg" alt="" />
                <h5>Muskan Agarwal</h5>
                <h6>92.20</h6>
              </div>
            </Col>
            <Col md={3}>
              <div className="resultbox">
              <img src="https://www.mpsckp.com/images/toppers/2023/12_3.jpg" alt="" />
                <h5>Manjusha Pradhan</h5>
                <h6>91.20</h6>
              </div>
            </Col>
            <Col md={3}>
              <div className="resultbox">
              <img src="https://www.mpsckp.com/images/toppers/2023/12_4.jpg" alt="" />
                <h5>Aditya Sharma</h5>
                <h6>90.00</h6>
              </div>
            </Col>
          </Row>
          <Row>
            <Col md={3}>
              <div className="resultbox">
                <img src="https://www.mpsckp.com/images/toppers/2023/12_5.jpg" alt="" />
                <h5>Suraiya Naaz</h5>
                <h6>88.80</h6>
              </div>
            </Col>
            <Col md={3}>
              <div className="resultbox">
              <img src="https://www.mpsckp.com/images/toppers/2023/12_6.jpg" alt="" />
                <h5>Pradhan Deogam</h5>
                <h6>87.60</h6>
              </div>
            </Col>
            <Col md={3}>
              <div className="resultbox">
              <img src="https://www.mpsckp.com/images/toppers/2023/12_7.jpg" alt="" />
                <h5>Rima Das</h5>
                <h6>85.20</h6>
              </div>
            </Col>
            <Col md={3}>
              <div className="resultbox">
              <img src="https://www.mpsckp.com/images/toppers/2023/12_8.jpg" alt="" />
                <h5>Shiva Munda</h5>
                <h6>83.80</h6>
              </div>
            </Col>
          </Row>
          <Row>
            <Col md={3}>
              <div className="resultbox">
                <img src="https://www.mpsckp.com/images/toppers/2023/12_9.jpg" alt="" />
                <h5>Azmeena Hussain</h5>
                <h6>83.60</h6>
              </div>
            </Col>
            <Col md={3}>
              <div className="resultbox">
              <img src="https://www.mpsckp.com/images/toppers/2023/12_10.jpg" alt="" />
                <h5>Baby Kumari Samad</h5>
                <h6>83.40</h6>
              </div>
            </Col>
            <Col md={3}>
              <div className="resultbox">
              <img src="https://www.mpsckp.com/images/toppers/2023/12_11.jpg" alt="" />
                <h5>Sumi Soren</h5>
                <h6>83.40</h6>
              </div>
            </Col>
            <Col md={3}>
              <div className="resultbox">
              <img src="https://www.mpsckp.com/images/toppers/2023/12_12.jpg" alt="" />
                <h5>Rishav Singhdeo</h5>
                <h6>83.40</h6>
              </div>
            </Col>
          </Row>

        </Container>
        
        <Container>
          <Row className="result">
            <Col>
            <h4>CLASS X TOPPERS - 2023</h4></Col>
          </Row>
        </Container>
        <Container>
          <Row>
            <Col md={3}>
              <div className="resultbox">
                <img src="https://www.mpsckp.com/images/toppers/2023/10_1.jpg" alt="" />
                <h5>Divita Kejriwal</h5>
                <h6>96.40</h6>
              </div>
            </Col>
            <Col md={3}>
              <div className="resultbox">
              <img src="https://www.mpsckp.com/images/toppers/2023/10_2.jpg" alt="" />
                <h5>Raj Purty</h5>
                <h6>95.00</h6>
              </div>
            </Col>
            <Col md={3}>
              <div className="resultbox">
              <img src="https://www.mpsckp.com/images/toppers/2023/10_3.jpg" alt="" />
                <h5>Anisa Khatoon</h5>
                <h6>94.80</h6>
              </div>
            </Col>
            <Col md={3}>
              <div className="resultbox">
              <img src="https://www.mpsckp.com/images/toppers/2023/10_4.jpg" alt="" />
                <h5>Misbah Aazmi</h5>
                <h6>94.60</h6>
              </div>
            </Col>
          </Row>
          <Row>
            <Col md={3}>
              <div className="resultbox">
                <img src="https://www.mpsckp.com/images/toppers/2023/10_5.jpg" alt="" />
                <h5>Vickey Manjhi</h5>
                <h6>93.80</h6>
              </div>
            </Col>
            <Col md={3}>
              <div className="resultbox">
              <img src="https://www.mpsckp.com/images/toppers/2023/10_6.jpg" alt="" />
                <h5>Kumar Priyanshu</h5>
                <h6>93.60</h6>
              </div>
            </Col>
            <Col md={3}>
              <div className="resultbox">
              <img src="https://www.mpsckp.com/images/toppers/2023/10_7.jpg" alt="" />
                <h5>Jaipal Singh Pareya</h5>
                <h6>92.40</h6>
              </div>
            </Col>
            <Col md={3}>
              <div className="resultbox">
              <img src="https://www.mpsckp.com/images/toppers/2023/10_8.jpg" alt="" />
                <h5>Pankaj Kumar Mahto</h5>
                <h6>92.20</h6>
              </div>
            </Col>
          </Row>
          <Row>
            <Col md={3}>
              <div className="resultbox">
              <img src="https://www.mpsckp.com/images/toppers/2023/10_9.jpg" alt="" />
                <h5>Roshan Mishra</h5>
                <h6>92.20</h6>
              </div>
            </Col>
            <Col md={3}>
              <div className="resultbox">
                <img src="https://www.mpsckp.com/images/toppers/2023/10_10.jpg" alt="" />
                <h5>Rudrajyoti Shit</h5>
                <h6>92.20</h6>
              </div>
            </Col>
            <Col md={3}>
              <div className="resultbox">
              <img src="https://www.mpsckp.com/images/toppers/2023/10_11.jpg" alt="" />
                <h5>Zaid Uddin</h5>
                <h6>91.60</h6>
              </div>
            </Col>
            <Col md={3}>
              <div className="resultbox">
              <img src="https://www.mpsckp.com/images/toppers/2023/10_12.jpg" alt="" />
                <h5>Radhika Chourasia</h5>
                <h6>90.80</h6>
              </div>
            </Col>
          </Row>
          <Row>

          <Col md={3}>
              <div className="resultbox">
              <img src="https://www.mpsckp.com/images/toppers/2023/10_13.jpg" alt="" />
                <h5>Binit Tripathy</h5>
                <h6>90.80</h6>
              </div>
            </Col>
            <Col md={3}>
              <div className="resultbox">
              <img src="https://www.mpsckp.com/images/toppers/2023/10_14.jpg" alt="" />
                <h5>Sambhav Nanda</h5>
                <h6>90.40</h6>
              </div>
            </Col>
          </Row>
        </Container>
      </div>
    </div>
  );
};

export default Result;
