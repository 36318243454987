import React from "react";
import { Carousel, Row, Col } from "react-bootstrap";
import { Link } from "react-router-dom";
export const GalleryCarousel = () => {
  return (
    <div>
      <Carousel fade>
        <Carousel.Item>
          <Row>
            <Col md={4}>
              <div className="mpsgallery">
                <Link to={"/AnnualSports2023"}>
                  <img
                    src="http://www.mpsckp.com/gallery/annual_sports_23/1.jpg"
                    alt=""
                    className="img-fluid"
                  />
                </Link>
                <div className="mpsboxdetails">
                  <Link to={"/AnnualSports2023"}>Annual Sports 2023</Link>
                </div>
              </div>
            </Col>
            <Col md={4}>
              <div className="mpsgallery">
                <Link to={"/AnnualDay2023"}>
                  <img
                    src="http://www.mpsckp.com/gallery/annual_day_23/1.jpg"
                    alt=""
                    className="img-fluid"
                  />
                </Link>
                <div className="mpsboxdetails">
                  <Link to={"/AnnualDay2023"}>Annual Day 2023</Link>
                </div>
              </div>
            </Col>

            
            <Col md={4}>
              <div className="mpsgallery">
                <Link to={"/ScienceExhibition"}>
                  <img
                    src="http://www.mpsckp.com/gallery/science_exhibition_22/thumb.webp"
                    alt=""
                    className="img-fluid"
                  />
                </Link>
                <div className="mpsboxdetails">
                  <Link to={"/ScienceExhibition"}>Science Exhibition 2022</Link>
                </div>
              </div>
            </Col>
          </Row>
        </Carousel.Item>
        <Carousel.Item>
          <Row>
            <Col md={4}>
              <div className="mpsgallery">
                <Link to={"/SportsDay"}>
                  <img
                    src="http://www.mpsckp.com/gallery/sports_day_22/thumb.webp"
                    alt=""
                    className="img-fluid"
                  />
                </Link>
                <div className="mpsboxdetails">
                  <Link to={"/SportsDay"}>Sports Day 2022</Link>
                </div>
              </div>
            </Col>
            <Col md={4}>
              <div className="mpsgallery">
                <Link to={"/IndependenceDay"}>
                  <img
                    src="http://www.mpsckp.com/gallery/id_2022/thumb.webp"
                    alt=""
                    className="img-fluid"
                  />
                </Link>
                <div className="mpsboxdetails">
                  <Link to={"/IndependenceDay"}>Independence Day 2022</Link>
                </div>
              </div>
            </Col>
            <Col md={4}>
              <div className="mpsgallery">
                <Link to={"/ChildrenDay"}>
                  <img
                    src="http://www.mpsckp.com/gallery/children_day_22/thumb.webp"
                    alt=""
                    className="img-fluid"
                  />
                </Link>
                <div className="mpsboxdetails">
                  <Link to={"/ChildrenDay"}>Children Day 2022</Link>
                </div>
              </div>
            </Col>
          </Row>
        </Carousel.Item>
        <Carousel.Item>
          <Row>
            <Col md={4}>
              <div className="mpsgallery">
                <Link to={"/Farewell"}>
                  <img
                    src="http://www.mpsckp.com/gallery/farewell_2022/thumb1.webp"
                    alt=""
                    className="img-fluid"
                  />
                </Link>
                <div className="mpsboxdetails">
                  <Link to={"/Farewell"}>Farewell 2022</Link>
                </div>
              </div>
            </Col>
            <Col md={4}>
              <div className="mpsgallery">
                <Link to={"/Essay"}>
                  <img
                    src="http://www.mpsckp.com/gallery/essay_2022/1.webp"
                    alt=""
                    className="img-fluid"
                  />
                </Link>
                <div className="mpsboxdetails">
                  <Link to={"/Essay"}>Essay</Link>
                </div>
              </div>
            </Col>
            <Col md={4}>
              <div className="mpsgallery">
                <Link to={"/Calligraphy"}>
                  <img
                    src="http://www.mpsckp.com/gallery/calligraphy_2022/1.webp"
                    alt=""
                    className="img-fluid"
                  />
                </Link>
                <div className="mpsboxdetails">
                  <Link to={"/Calligraphy"}>Calligraphy</Link>
                </div>
              </div>
            </Col>
          </Row>
        </Carousel.Item>
        <Carousel.Item>
          <Row>
            <Col md={4}>
              <div className="mpsgallery">
                <Link to={"/School"}>
                  <img
                    src="http://www.mpsckp.com/gallery/school/images/1.webp"
                    alt=""
                    className="img-fluid"
                  />
                </Link>
                <div className="mpsboxdetails">
                  <Link to={"/School"}>The School</Link>
                </div>
              </div>
            </Col>
            <Col md={4}>
              <div className="mpsgallery">
                <Link to={"/RepublicDay"}>
                  <img
                    src="http://www.mpsckp.com/gallery/republic_day_20/1.webp"
                    alt=""
                    className="img-fluid"
                  />
                </Link>
                <div className="mpsboxdetails">
                  <Link to={"/RepublicDay"}>Republic Day</Link>
                </div>
              </div>
            </Col>
            <Col md={4}>
              <div className="mpsgallery">
                <Link to={"/PrizeDistribution"}>
                  <img
                    src="http://www.mpsckp.com/gallery/prize_distribution_20/1.webp"
                    alt=""
                    className="img-fluid"
                  />
                </Link>
                <div className="mpsboxdetails">
                  <Link to={"/PrizeDistribution"}>Prize Distribution</Link>
                </div>
              </div>
            </Col>
          </Row>
        </Carousel.Item>
      </Carousel>
    </div>
  );
};
