import React from "react";
import Menu from "./Menu";
import '../fonts/LithosPro-Black.woff';
import "./App.css";

function App() {
  
  return (
    <div>
      <header className="top"></header>
      <section className="mpstoparea">
        <Menu />
      </section>
      
    </div>
  );
}

export default App;
