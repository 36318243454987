import React from "react";
import Slider from "react-animated-slider";
import "react-animated-slider/build/horizontal.css";
import "./slider.css";
import 'animate.css/animate.css';
import slide1 from "../images/slide1.webp";
// import slide2 from "../images/slide2.webp";
import slide3 from "../images/slide3.webp";
// import slide4 from "../images/slide4.webp";
import slide5 from "../images/slide5.webp";
import slide6 from "../images/slide6.webp";
import slide7 from "../images/slide7.webp";
import { Button } from "react-bootstrap";
const content = [
  // {
  //   title: "",
  //   description: "",
  //   button: "",
  //   image:"http://www.mpsckp.com/images/mps20230401.jpg",
  //   btnlink:""
  // },
  {
    title: "Admission Open (Class XI)",
    description: "",
    button: "Apply Now",
    image: slide1,
    btnlink:"http://www.mpsckp.com/online_application.php"
  },
  {
    title: "",
    description: "",
    button: "",
    image: slide1,
  },
  {
    title: "",
    description: "",
    button: "",
    image: slide3,
  },
  {
    title: "",
    description: "",
    button: "",
    image: slide5,
  },
  {
    title: "",
    description: "",
    button: "",
    image: slide6,
  },
  {
    title: "",
    description: "",
    button: "",
    image: slide7,
  },
];
function MainSlider() {
  return (
    <div>
      <Slider className="slider-wrapper" autoplay={3000}>
        {content.map((item, index) => (
          <div
            key={index}
            className="slider-content"
            style={{
              background: `url('${item.image}') no-repeat center center`,
            }}
          >
            <div className="innerslide">
              <h1 className="animate__bounceInDown">{item.title}</h1>

              {item.description !== "" ? <p>{item.description}</p> : null}

              {item.button !== "" ? <a className="btn" href="http://www.mpsckp.com/online_application.php" target="_blank"
                    rel="noreferrer"><Button>{item.button}</Button></a> : null}
            </div>
          </div>
        ))}
      </Slider>
    </div>
  );
}

export default MainSlider;
